import React, { useState } from "react";
import Styles from "../../Styles/HeaderComp.module.css";
import MainLogo from "../MainLogo/MainLogo";
import { Link } from "react-router-dom";

const HeaderComp = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <header className={Styles["Landing-Header-div"]}>
        <div className={Styles["Landing-header-logo"]}>
          <MainLogo />
        </div>

        <nav className={Styles["Landing-Header-navbar"]}>
          <ul>
            <li>
              <Link to="/Studio">Studio</Link>{" "}
            </li>
            <li>
              <Link to="/Services">Services</Link>{" "}
            </li>
            <li>
              <Link to="/CaseStudy">Case Studies</Link>
            </li>
            <li>
              <Link to="/Clients">Clients</Link>
            </li>
            <Link to="/ContactUs" className={Styles["Header-Link"]}>
              <button>Contact Us</button>
            </Link>
          </ul>
        </nav>
      </header>
      <header className={Styles["MobileHeader"]}>
        <Link to="/" className={Styles["logo"]}>
          <MainLogo></MainLogo>
        </Link>
        <p
          className={`${Styles.menuBtn} ${isActive ? Styles.act : ""}`}
          onClick={handleClick}
        >
          <span className={Styles["lines"]}></span>
        </p>
        <nav className={`${Styles.mainMenu} ${isActive ? Styles.act : ""}`}>
          <ul>
            <li>
              <Link to="/" onClick={handleClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/Studio" onClick={handleClick}>
                Studio
              </Link>
            </li>
            <li>
              <Link to="/Services" onClick={handleClick}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/CaseStudy" onClick={handleClick}>
                Case Studies
              </Link>
            </li>
            <li>
              <Link to="/Clients" onClick={handleClick}>
                Clients
              </Link>
            </li>
            <li>
              <Link
                to="/ContactUs"
                className={Styles["suBtn"]}
                onClick={handleClick}
              >
                Contact us
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default HeaderComp;
