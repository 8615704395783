import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  LandingPage,
  Services,
  CaseStudies,
  ContactUs,
  Clients,
  Studio,
} from "../Pages/PageIndex";
import Moj from "../Pages/CaseStudies/Moj";
import Vita from "../Pages/CaseStudies/Vita";
import StudiobookingForm from "../Pages/Studio/StudioBookingpage";
import { Helmet } from "react-helmet";

const RouteComponent = () => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#fff" />
        <title>KrazyFox Website</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/Services" element={<Services />} />
        <Route path="/CaseStudy" element={<CaseStudies />} />
        <Route path="/CaseStudy/Moj" element={<Moj />} />
        <Route path="/CaseStudy/Vita" element={<Vita />} />
        <Route exact path="/Clients" element={<Clients />} />
        <Route exact path="/Studio" element={<Studio />} />
        <Route exact path="/Studio/BookNow" element={<StudiobookingForm />} />
        <Route exact path="ContactUs" element={<ContactUs />} />
      </Routes>
    </>
  );
};

export default RouteComponent;
