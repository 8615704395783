import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CarouselComp({ children, Showarrow, seconds }) {
  return (
    <Carousel
      responsive={responsive}
      containerClass="Caraousel-item"
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={seconds ? seconds : 3000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      arrows={false}
      showDots={Showarrow ? true : false}
    >
      {children}
    </Carousel>
  );
}

export default CarouselComp;
