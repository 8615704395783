import React, { useState } from "react";
import Iphone from "../UI/IphoneCaseStudy/Iphone";
import Styles from "../../Styles/BrandMobileVersion.module.css";
import hunnitLogo from "../../Assets/Images/CaseStudy/CaseStudyComp2/hunnit logo.png";
import Hunnitinfo from "../../Assets/Images/CaseStudy/CaseStudyComp2/hunnit card 2.png";
import first from "../../Assets/Images/CaseStudy/CaseStudyComp2/1st.png";
import secound from "../../Assets/Images/CaseStudy/CaseStudyComp2/2nd.png";
import third from "../../Assets/Images/CaseStudy/CaseStudyComp2/3rd.png";
import { influencers2 } from "../../Arrays/influenserArray";
import vid1 from "../../Assets/video/CaseStudy2/RainaUttam.mp4";
import vid2 from "../../Assets/video/CaseStudy2/sainyatanija.mp4";
import vid3 from "../../Assets/video/CaseStudy2/Simranbedi.mp4";

const BrandMobileVersion2 = () => {
  const [showvid, setShowVid] = useState(null);

  const SetVideoMethod = (index) => {
    setShowVid((prevShowVid) => (prevShowVid === index ? null : index));
  };

  const renderedInfluencers = influencers2.map((influencer, index) => (
    <div
      key={index}
      className={`${Styles["Influencer-box-small"]} ${
        index === 1 ? Styles["second-influencer-box"] : ""
      }`}
    >
      <img
        src={showvid === index ? influencer.closebtn : influencer.imgSrc}
        className={Styles["Influencer-Img-small"]}
        alt="influencer"
        onClick={() => {
          SetVideoMethod(index);
        }}
      />

      <div className={Styles["Influencer-Name-small"]}>
        {showvid !== index && (
          <img src={influencer.socialMediaIcon} alt="insta" />
        )}
        <span>{showvid === index ? "Hunnit" : influencer.name}</span>
      </div>
    </div>
  ));
  return (
    <article className={Styles.BrandMobileVersion}>
      <div
        className={Styles.CarouselMobiles}
        style={{
          background:
            "linear-gradient(0deg,#F48164 30%, rgba(255, 255, 255, 1) 100%)",
        }}
      >
        <div className={Styles.CarouselMobileUpperimg}>
          {showvid === null && <img src={hunnitLogo} alt="Urban Company" />}
          {showvid === 0 && (
            <>
              <img src={first} alt="likes 1" /> <p>naina_uttam</p>
            </>
          )}
          {showvid === 1 && (
            <>
              <img src={secound} alt="likes 2" /> <p>sanya_tanejaa</p>
            </>
          )}
          {showvid === 2 && (
            <>
              <img src={third} alt="likes 3" /> <p>simransethibedi</p>
            </>
          )}
        </div>
        {showvid === null && <Iphone imgSrc={Hunnitinfo} />}
        {showvid === 0 && <Iphone videoSrc={vid1} />}
        {showvid === 1 && <Iphone videoSrc={vid2} />}
        {showvid === 2 && <Iphone videoSrc={vid3} />}
        <div className={Styles.CarouselMobileImg}>{renderedInfluencers}</div>
      </div>
    </article>
  );
};

export default BrandMobileVersion2;
