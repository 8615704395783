import DCCServices from "../Assets/Images/Services/digital content creation.png";
import DSServices from "../Assets/Images/Services/digital strategy.png";
import MCServices from "../Assets/Images/Services/meme marketing.png";
import IFMServices from "../Assets/Images/Services/influencer out reach management.png";
import SMMServices from "../Assets/Images/Services/social media marketing.png";
import TMServices from "../Assets/Images/Services/talent Management.png";

export const ServicesContentArray = [
  {
    heading: "Influencer & Outreach Management",
    ContentImg: IFMServices,
    color: "#5A92FF",
    info: "By locating and collaborating with the most appropriate influencers, our firm is exceptional at generating the ideal level of FOMO for your business. We have worked with influencers from a variety of industries, such as lifestyle, pharma, and entertainment.",
  },
  {
    heading: "Digital Strategy",
    ContentImg: DSServices,
    color: "#00B3CE",
    info: "We elevate your campaign's impact by utilizing social listening, strategic buying, influencer marketing, third-party partnerships, and engaging content to create a comprehensive plan of action that resonates with your audience and strengthens your brand.",
  },
  {
    heading: "Digital Content Creation",
    ContentImg: DCCServices,
    color: "#B900B3",
    info: "Whether you're looking for quirky humor or informative wit, we've got you covered. KrazyFox crafts content strategies that cater to your brand's unique voice and execute them flawlessly to leave a lasting impression on your consumers.",
  },
  {
    heading: "Talent Management",
    ContentImg: TMServices,
    color: "#F4564A",
    info: "At Krazyfox, we are experts at spotting and developing talent, providing creators with assistance in crucial areas like monetization, collaboration, and public relations. Our objective is to support artists in developing their personal brands while making sure their demands are completely recognized and satisfied.",
  },
  {
    heading: "Social Media Marketing",
    ContentImg: SMMServices,
    color: "#EA336F",
    info: "Our team is aware of the significance of a digital-first strategy in the modern marketplace. Using a combination of marketing tools designed specifically for your company's requirements, we assist your brand in growing its digital footprint and connecting with consumers on a deeper level.",
  },
  {
    heading: "Meme Campaigns",
    ContentImg: MCServices,
    color: "#7F1DFF",
    info: "Our meme campaign service is a unique way to engage your audience and increase your brand awareness. Memes are a popular form of content that are easily shareable and can go viral quickly. Our team of experts will help you create and distribute custom memes that are relevant to your industry and brand.",
  },
];
