import React, { useEffect, useState } from "react";
// css file
import Styles from "../../Styles/LandingPage.module.css";
//Reusable component import
import Banner from "../../Components/UI/Banner/Banner";
import Iphone from "../../Components/UI/Iphone/Iphone";
import MainLogo from "../../Components/MainLogo/MainLogo";
import HeaderandInfo from "../../Components/UI/Header&info/HeaderandInfo";
import SuccessComponent from "../../Components/SuccessComponent/SuccessComponent";

//Landing banner assets
import MainVideo from "../../Assets/video/main-video.mp4";

//Services assets
import { ServicesContentArray } from "../../Arrays/ServicesCardArray";
import servicesLikes from "../../Assets/Images/Services/ourServiceLikes.png";

// Studio Assets -------------------------------------------------------------------
import StudioIndorImg from "../../Assets/Images/studio section/upscaled indoor image/9 .png";
import StudioIndorImg2 from "../../Assets/Images/studio section/upscaled indoor image/11.png";
import StudioOutdoorImg from "../../Assets/Images/studio section/upscaled outdoor image/1.png";
import BluePin from "../../Assets/Images/studio section/pin & likes/Blue Pin.png";
import GreenPin from "../../Assets/Images/studio section/pin & likes/Green Pin.png";
import RedPin from "../../Assets/Images/studio section/pin & likes/Red Pin.png";
import Like1 from "../../Assets/Images/studio section/pin & likes/Group 12.png";
import Like2 from "../../Assets/Images/studio section/pin & likes/Group 17.png";
import Like3 from "../../Assets/Images/studio section/pin & likes/Group 18.png";

//Case Study Assests
import MojImg from "../../Assets/Images/CaseStudy/mojStudy.png";
import MojVideo from "../../Assets/video/KRAZYFOX X MOJ.mp4";
import VitaVideo from "../../Assets/video/KRAZY FOX X VITA.mp4";
import VitaImg from "../../Assets/Images/CaseStudy/VitaStudy.png";
import CaseStudyContentComp from "../../Components/CaseStudyContentComp/CaseStudyContentComp";
import CaseStudyContentComp2 from "../../Components/CaseStudyContentComp/CaseStudyContentComp2";

//Clients Assets
import ClientsComponent from "../../Components/ClientsComponent/ClientsComponent";
import { ClientsImgArray } from "../../Arrays/ClientsListArray";
import SeeMorebutton from "../../Components/UI/SeeMoreButton/SeeMorebutton";

//Media Assets
import { MediaPreArrays } from "../../Arrays/MediaPreArray";
import CarouselComp from "../../Components/UI/Carousel/Carousel";

//Simple Approach
import { SimpleAproachArray } from "../../Arrays/SimpleAprroachArray";
import ContactUsButton from "../../Components/UI/ContactUsButton/ContactUsButton";
import BrandMobileVersion from "../../Components/RecentBrandMobileVersion/BrandMobileVersion";
import { Link } from "react-router-dom";
import BrandMobileVersion2 from "../../Components/RecentBrandMobileVersion/Brand2";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  //For clients
  const [clientImgArray, setClientImgArray] = useState(
    ClientsImgArray.slice(0, 8)
  );
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const shuffledArray = shuffleArray(ClientsImgArray).slice(0, 8);
      setClientImgArray(shuffledArray);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // End for clients
  // services
  const [activeIndexS, setActiveIndexS] = useState(null);
  const handleMouseEnter = (index) => {
    setActiveIndexS(index);
  };

  const handleMouseOut = () => {
    setActiveIndexS(null);
  };
  const [activeIndexS2, setActiveIndexS2] = useState(null);
  const handleMouseEnter2 = (index) => {
    setActiveIndexS2(index);
  };

  const handleMouseOut2 = () => {
    setActiveIndexS2(null);
  };

  //Simple Approach
  const [Showarrow] = useState(true);
  return (
    <React.Fragment>
      <Helmet>
        <title>KrazyFox - Key to Effective Digital Marketing Strategies</title>
        <meta
          name="description"
          content="KrazyFox is a leading digital marketing agency offering a wide range of services including influencer marketing, digital content creation, social media management, meme campaigns, and talent management. Maximize your online presence and boost your brand with our expert strategies."
        />
        <meta
          name="keywords"
          content="KrazyFox, digital marketing agency, influencer marketing, digital content creation, social media management, meme campaigns, talent management"
        />
        <meta name="author" content="KrazyFox" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Banner className={Styles["Landing-Banner"]}>
        <div className={Styles["Landing-banner-intro"]}>
          <MainLogo className={Styles["Landing-main-Header"]} />
          <p className={Styles["Landing-Banner-para"]}>
            <span className="scale-in-left">WE ARE BRAND DEVELOPMENT</span>
            <span className="scale-in-right">INFLUENCER MARKETING,</span>
            <span className="scale-in-left">AND CONTENT CREATION</span>
            <span className="scale-in-right">COMPANY</span>
          </p>
          <ContactUsButton className={Styles["Landing-banner-contact-btn"]} />
        </div>
        <Iphone
          MainVideo={MainVideo}
          className={Styles["Landing-banner-iphone"]}
        />
      </Banner>
      <section className={Styles["Landing-page-main-content"]}>
        <article className={Styles["Landing-page-Service-article"]}>
          <div className={Styles["Services-intro"]}>
            <HeaderandInfo title={"Services"}>
              <h2>Our Services</h2>
              <p>
                Let us help you achieve your brand goals by designing effective
                strategies and creating compelling content. Our comprehensive
                range of services includes Digital Strategy, social media
                marketing, content marketing, influencer outreach, Meme
                Campaigns, Talent Management, digital content Creation, and
                more.
              </p>
              <img src={servicesLikes} alt="Services Like" />
            </HeaderandInfo>
          </div>
          <div className={Styles["Landing-Page-Services-content-row-1"]}>
            {ServicesContentArray &&
              ServicesContentArray.slice(0, 3).map((item, index) => {
                return (
                  <div
                    className={`${Styles["Service-item"]} ${
                      activeIndexS === index ? Styles.active : ""
                    } ${
                      activeIndexS !== index && activeIndexS !== null
                        ? Styles.notActive
                        : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseOut}
                    key={index + 1}
                    style={{ backgroundColor: item.color }}
                  >
                    <h3>{item.heading}</h3>
                    <div>
                      <img
                        src={item.ContentImg}
                        className={`img${index + 1}`}
                        alt={item.heading}
                      />
                      <p>{item.info}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={Styles["Landing-Page-Services-content-row-1"]}>
            {ServicesContentArray &&
              ServicesContentArray.slice(3).map((item, index) => {
                return (
                  <div
                    className={`${Styles["Service-item"]} ${
                      activeIndexS2 === index + 3 ? Styles.active2 : ""
                    } ${
                      activeIndexS2 !== index + 3 && activeIndexS2 !== null
                        ? Styles.notActive2
                        : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter2(index + 3)}
                    onMouseLeave={handleMouseOut2}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <h3>{item.heading}</h3>
                    <div>
                      <img
                        src={item.ContentImg}
                        className={`img${index + 1}`}
                        alt={item.heading}
                      />
                      <p>{item.info}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={Styles["Landing-Page-Services-content-for-mobile"]}>
            {ServicesContentArray &&
              ServicesContentArray.map((item, index) => {
                return (
                  <div
                    className={Styles["Service-item"]}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <Link to="/Services">
                      <h3>{item.heading}</h3>
                      <div>
                        <img
                          src={item.ContentImg}
                          className={`img${index + 1}`}
                          alt={item.heading}
                        />
                        <p>{item.info}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </article>

        <article className={Styles["Landing-page-studio"]} title="Studio">
          <div>
            <MainLogo className={Styles["LandingStudio-section-header"]} />
            <h3>Studio</h3>
            <p>
              Our content creating studio is a dynamic and innovative space
              dedicated to producing high-quality digital content for a variety
              of platforms and audiences. We craft engaging and effective
              content for social media, podcasts, videos, product photography
              and more.......
            </p>
            <Link className="header-link" to="Studio">
              {" "}
              <SeeMorebutton />
            </Link>
          </div>
          <div className={Styles["Studio-img-container"]}>
            <div className={Styles["Studio-img-div1"]}>
              <img src={BluePin} alt="BluePin" className={Styles.Studiopins} />
              <img src={Like1} alt="Likes1" className={Styles.StudioLikes} />
              <img src={StudioIndorImg} alt="Upscaled Outdoor Img" />
              <p>Creative hub for Content</p>
            </div>
            <div className={Styles["Studio-img-div2"]}>
              <img src={RedPin} alt="RedPin" className={Styles.Studiopins} />
              <img src={Like3} alt="Likes2" className={Styles.StudioLikes} />
              <img src={StudioIndorImg2} alt="Upscaled Outdoor Img" />
              <p>A Place for Creators.</p>
            </div>
            <div className={Styles["Studio-img-div3"]}>
              <img
                src={GreenPin}
                alt="GreenPIN"
                className={Styles.Studiopins}
              />
              <img src={Like2} alt="Likes3" className={Styles.StudioLikes} />
              <img src={StudioOutdoorImg} alt="Upscaled Outdoor Img" />
              <p>16 different Sets to full fill your creation needs</p>
            </div>
          </div>
        </article>
        <article className={Styles["Landing-Number-Success"]}>
          <HeaderandInfo>
            <h2>Numbers That Guarantees Success</h2>
          </HeaderandInfo>
          <SuccessComponent />
        </article>
        <CaseStudyContentComp />
        <CaseStudyContentComp2 />
        <CarouselComp seconds={10000} Showarrow={true}>
          <BrandMobileVersion />
          <BrandMobileVersion2 />
        </CarouselComp>
        <Link className="header-link" to="CaseStudy">
          {" "}
          <SeeMorebutton />
        </Link>
        <article>
          <HeaderandInfo>
            <h2>Case Studies</h2>
            <p>
              Our ideas and strategies are fueled by enthusiasm, ingenuity, and
              imagination. By blending insights with various media, we develop
              campaigns that ignite passion and inspire action.
            </p>
          </HeaderandInfo>

          <div className={Styles["Landing-Case-Study-img"]}>
            <Link to="/CaseStudy/Vita">
              <div className={Styles["VitaCaseStudy-img"]}>
                <div className={Styles["background"]}></div>
                <img src={VitaImg} alt="Vita Case Study" />
                <p>
                  #Partnership <br />
                  KRAZYFOX and Vita
                </p>
                <p>Read about our Amazing patnership with Vita</p>
                <video autoPlay loop muted playsInline>
                  <source src={VitaVideo} alt="Vita" />
                  Video not supported
                </video>
              </div>
            </Link>
            <Link to="/CaseStudy/Moj">
              <div className={Styles["MojCaseStudy-box"]}>
                <img src={MojImg} alt="Moj Case Study" />
                <div className={Styles["background"]}></div>
                <p>
                  #Partnership <br />
                  KRAZYFOX and Moj
                </p>
                <p>Read about our Amazing patnership with Moj</p>
                <video autoPlay loop muted playsInline>
                  <source src={MojVideo} alt="Moj" />
                  Video not supported
                </video>
              </div>
            </Link>
          </div>
          {/* for mobile screen ------------------------------------------------- */}
          <CarouselComp Showarrow={Showarrow} secounds={5000}>
            <div className={Styles["VitaCaseStudy-img-small"]}>
              <Link to="/CaseStudy/Vita">
                <img src={VitaImg} alt="Vita Case Study" />
              </Link>
            </div>
            <div className={Styles["MojCaseStudy-box-small"]}>
              <Link to="/CaseStudy/Moj">
                <img src={MojImg} alt="Moj Case Study-small" />
              </Link>
            </div>
          </CarouselComp>
        </article>
        <article>
          <div className={Styles["Landing-Case-Studies-info"]}>
            <HeaderandInfo>
              <h2>Delighted Clients = Organic Growth</h2>
              <p>
                Working with a wide range of clients has given us a deeper
                understanding of different industries and enabled us to deliver
                innovative solutions.
              </p>
            </HeaderandInfo>
          </div>
          <ClientsComponent ClientsImgArray={clientImgArray} />
          <Link className="header-link" to="Clients">
            <SeeMorebutton />
          </Link>
        </article>

        <article className={Styles["SimpleAproach-section"]}>
          <HeaderandInfo>
            <h2>Simple Approach to Deliver Amazing Results</h2>
          </HeaderandInfo>
          <CarouselComp Showarrow={Showarrow} seconds={3000}>
            {SimpleAproachArray &&
              SimpleAproachArray.map((item, index) => {
                return (
                  <div key={index + 1} className={Styles["approach-box"]}>
                    <div className={Styles.SimpleAprroachInfo}>
                      <span style={{ color: item.number }}>{`0${
                        index + 1
                      }`}</span>
                      <h2 style={{ color: item.number }}>{item.heading2}</h2>
                      <ul>
                        {item.content.map((step, index) => (
                          <li key={index}>
                            <h3>{step.heading3}</h3>
                            <p>{step.paragraph}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={Styles.SimpleAprroachImg}>
                      <img
                        src={item.background}
                        alt={`background${index + 1}`}
                        className={Styles[`background${index + 1}`]}
                      />
                    </div>
                  </div>
                );
              })}
          </CarouselComp>
        </article>

        <article>
          <HeaderandInfo>
            <h2>Our Media Presence</h2>
          </HeaderandInfo>
          <div className={Styles["Media-presence-box"]}>
            {MediaPreArrays &&
              MediaPreArrays.map((item, index) => {
                return (
                  <a href={item.link} target="_blank" key={index + 1}>
                    <img src={item.src} alt={`media${index + 1}`} />
                  </a>
                );
              })}
          </div>
        </article>
        <article className={Styles.FAQ}>
          <HeaderandInfo>
            <h2>Frequently Asked Question</h2>
            <p>
              Here are some of the most common questions we get asked. If you
              can't find the answer you're looking for, feel free to contact us.
            </p>
          </HeaderandInfo>
          <div className={Styles.faqcontainer}>
            <details>
              <summary>What is KrazyFox and what do we do?</summary>
              <p>
                KrazyFox is a 360° digital marketing agency that specializes in
                helping both influencers and brands achieve their goals through
                innovative strategies and techniques.
              </p>
            </details>
            <details>
              <summary>
                Can KrazyFox help me grow my social media presence as an
                influencer?
              </summary>
              <p>
                Absolutely! Our team of experts can help you develop a tailored
                strategy to grow your audience, increase engagement, and
                monetize your content.
              </p>
            </details>
            <details>
              <summary>
                I'm a brand looking to increase my online visibility. Can
                KrazyFox help?
              </summary>
              <p>
                Definitely! We work with brands to develop unique and effective
                marketing strategies that drive growth and success. With our
                PAN-India access and regional dialect expertise, we have the
                tools to help you succeed.
              </p>
            </details>
            <details>
              <summary>
                How does KrazyFox stay up to date on the latest trends and
                techniques in digital marketing?
              </summary>
              <p>
                We're constantly learning and experimenting with new techniques
                and strategies to stay ahead of the curve. Plus, with our large
                creator network and team of over 250 experts, we have access to
                a wealth of knowledge and expertise.
              </p>
            </details>
            <details>
              <summary>How can I get started with KrazyFox?</summary>
              <p>
                It's easy! Simply fill out our contact form and one of our team
                members will get in touch with you to discuss how we can help
                you achieve your goals.
              </p>
            </details>
          </div>
        </article>
      </section>
    </React.Fragment>
  );
};

export default LandingPage;
