import React from "react";
import Styles from "../../Styles/SuccessComponent.module.css";
import { numberBoxArray } from "../../Arrays/SuccessArray";

const SuccessComponent = ({ className }) => {
  return (
    <div className={Styles["Numbers-Box-Container"]}>
      {numberBoxArray &&
        numberBoxArray.map((item, index) => {
          return (
            <div key={index} className={Styles["Number-box-item"]}>
              <h3>{item.num}</h3>
              <p>
                {item.head}
                <img src={item.img} alt={item.head} />
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default SuccessComponent;
