import React from "react";
import Styles from "../../Styles/Clients.module.css";
import Banner from "../../Components/UI/Banner/Banner";
import ClientImg from "../../Assets/Images/clients/ClientsMainImg.png";
import ClientsComponent from "../../Components/ClientsComponent/ClientsComponent";
import HeaderandInfo from "../../Components/UI/Header&info/HeaderandInfo";
import { ClientsImgArray } from "../../Arrays/ClientsListArray";
import ContactUsButton from "../../Components/UI/ContactUsButton/ContactUsButton";
const Clients = () => {
  return (
    <React.Fragment>
      <Banner className={Styles.clientBanner}>
        <div className={Styles.ClientsIntroImg}>
          <img src={ClientImg} alt="ClientPNG" />
        </div>
        <div className={Styles.ClientsIntroInfo}>
          <h2> Our Clients </h2>
          <p>Curious About what our agency can do for your brand?</p>
          <ContactUsButton />
        </div>
      </Banner>
      <section>
        <HeaderandInfo>
          <h2>A selection of clients we've assisted</h2>
          <p>
            Working with a wide range of clients has given us a deeper
            understanding of different industries and enabled us to deliver
            innovative solutions.
          </p>
        </HeaderandInfo>
        <ClientsComponent ClientsImgArray={ClientsImgArray} />
      </section>
    </React.Fragment>
  );
};

export default Clients;
