import React, { useState } from "react";
import Iphone from "../UI/IphoneCaseStudy/Iphone";
import Styles from "../../Styles/BrandMobileVersion.module.css";
import UrbanLogo from "../../Assets/Images/CaseStudy/CaseStudyComp1/urban company.png";
import UrbanInfo from "../../Assets/Images/CaseStudy/CaseStudyComp1/urban company case study 1.png";
import LieshLikes from "../../Assets/Images/CaseStudy/CaseStudyComp1/leishaLikes.png";
import AayushiLikes from "../../Assets/Images/CaseStudy/CaseStudyComp1/AayushiLikes.png";
import katLikes from "../../Assets/Images/CaseStudy/CaseStudyComp1/KatLikes.png";
import { influencers1 } from "../../Arrays/influenserArray";
import vid1 from "../../Assets/video/CaseStudy1/ayushi verma.mp4";
import vid3 from "../../Assets/video/CaseStudy1/liesha.mp4";
import vid2 from "../../Assets/video/CaseStudy1/kat kriestien.mp4";

const BrandMobileVersion = () => {
  const [showvid, setShowVid] = useState(null);

  const SetVideoMethod = (index) => {
    setShowVid((prevShowVid) => (prevShowVid === index ? null : index));
  };

  const renderedInfluencers = influencers1.map((influencer, index) => (
    <div
      key={index}
      className={`${Styles["Influencer-box-small"]} ${
        index === 1 ? Styles["second-influencer-box"] : ""
      }`}
    >
      <img
        src={showvid === index ? influencer.closebtn : influencer.imgSrc}
        className={Styles["Influencer-Img-small"]}
        alt="Some tag"
        onClick={() => {
          SetVideoMethod(index);
        }}
      />

      <div className={Styles["Influencer-Name-small"]}>
        {showvid !== index && (
          <img src={influencer.socialMediaIcon} alt="insta" />
        )}
        <span>{showvid === index ? "Urban Co." : influencer.name}</span>
      </div>
    </div>
  ));
  return (
    <article className={Styles.BrandMobileVersion}>
      <div
        className={Styles.CarouselMobiles}
        style={{
          background:
            "linear-gradient(0deg,#4AA6FF 30%, rgba(255, 255, 255, 1) 100%)",
        }}
      >
        <div className={Styles.CarouselMobileUpperimg}>
          {showvid === null && <img src={UrbanLogo} alt="Urban Company" />}
          {showvid === 0 && (
            <>
              <img src={LieshLikes} alt="Urban Company" />
              <p>leishapatidarrr</p>
            </>
          )}
          {showvid === 1 && (
            <>
              <img src={AayushiLikes} alt="Urban Company" />
              <p>aayushiivermaa</p>
            </>
          )}
          {showvid === 2 && (
            <>
              <img src={katLikes} alt="Urban Company" />
              <p>kat.kristien</p>
            </>
          )}
        </div>
        {showvid === null && <Iphone imgSrc={UrbanInfo} />}
        {showvid === 0 && <Iphone videoSrc={vid3} />}
        {showvid === 1 && <Iphone videoSrc={vid1} />}
        {showvid === 2 && <Iphone videoSrc={vid2} />}
        <div className={Styles.CarouselMobileImg}>{renderedInfluencers}</div>
      </div>
    </article>
  );
};

export default BrandMobileVersion;
