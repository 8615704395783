import React from "react";
import Styles from "../../../Styles/IphoneCaseStudy.module.css";
const Iphone = React.memo(
  ({ video, className, isActive, videoSrc, imgSrc }) => {
    return (
      <div className={`${Styles["mobile-section"]} ${className}`}>
        <div className={`${Styles["iphone-x"]} `}>
          <div className={Styles["side"]}>
            <div className={Styles["screen"]}>
              {/* For Web Recent Brands  */}
              {isActive && (
                <video autoPlay loop playsInline>
                  <source src={video} type="video/mp4" />
                  Video is not Supported
                </video>
              )}
              {/* for studio page  */}
              {videoSrc && (
                <video autoPlay loop muted playsInline>
                  <source src={videoSrc} type="video/mp4" />
                </video>
              )}
              {imgSrc && <img src={imgSrc} alt="Urban info" />}
            </div>
          </div>
          <div className={Styles["line"]}></div>
          <div className={Styles["header"]}>
            <div className={Styles["sensor-1"]}></div>
            <div className={Styles["sensor-2"]}></div>
            <div className={Styles["sensor-3"]}></div>
          </div>
          <div className={Styles["volume-button"]}></div>
          <div className={Styles["power-button"]}></div>
        </div>
      </div>
    );
  }
);

export default Iphone;
