export const items = [
  {
    num: "01",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F1.png?alt=media&token=3a03f36e-60fb-46e9-bff8-143fe84fec5f",
    info: "From concept to creation, we've got you covered",
    colorText: "#9B0096",
  },
  {
    num: "02",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F2.png?alt=media&token=1d6e3cb4-06c9-46a3-ad64-9260f76066a3",
    info: "Unleash your Creativity with our professional Space",
    colorText: "#FF207D",
  },
  {
    num: "03",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F5.png?alt=media&token=0fa30a1c-2e8e-4d00-8fc9-fab800d74221",
    info: "A Place for Content, Podcasts, Product shoots",
    colorText: "#913CFF",
  },
  {
    num: "04",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F4.png?alt=media&token=7520a8b7-6730-411b-945a-e454da50dc27",
    info: "Your Next Location for Insta perfect Reels",
    colorText: "#F1000B",
  },
  {
    num: "05",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F11.png?alt=media&token=cce6bbf9-6889-467b-b0ec-308659de15ce",
    info: "We Make you go Viral",
    colorText: "#F05161",
  },
  {
    num: "06",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F9.png?alt=media&token=7f3df3a1-e5e1-4d23-b5a4-07e98d4400a2",
    info: "Make a statement with content that stands out from the crowd",
    colorText: "#008885",
  },
  {
    num: "07",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F12.jpeg?alt=media&token=4612ffaa-c566-49d5-a938-aa3f73277589",
    info: "Experience seamless content creation in our studio",
    colorText: "#913CFF",
  },
  {
    num: "08",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F13.jpeg?alt=media&token=7d276d24-ab25-4f00-a3e1-278f121fa61f",
    info: "Create, capture, and share your story with our studio space.",
    colorText: "#F05161",
  },
  {
    num: "09",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fcarousel%20img%2F6.png?alt=media&token=76fe238a-5597-4d0b-b403-5c382142f24f",
    info: "Unleash the power of visuals with our content creation studio",
    colorText: "#000",
  },
  {
    num: "10",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20outdoor%20img%2F4.png?alt=media&token=229c7ae4-b242-47d0-b5f0-af70d49d28f1",
    info: "Take your brand to the next level with our professional studio space",
    colorText: "orange",
  },
];
