import React, { useState } from "react";
import Styles from "../../Styles/CaseStudyContainComp.module.css";
import Iphone from "../UI/IphoneCaseStudy/Iphone";
import { influencers2 } from "../../Arrays/influenserArray";
import Hunnit from "../../Assets/Images/CaseStudy/CaseStudyComp2/hunnit logo.png";

const CaseStudyContentComp2 = () => {
  const [isActive, setISActive] = useState(null);
  const [mainPhone, setMainPhone] = useState(true);
  const renderedInfluencers = influencers2.map((influencer, index) => (
    <>
      <div key={index+1} className={Styles["Influencer-box"]}>
        <img
          src={influencer.imgSrc}
          className={Styles["Influencer-Img"]}
          alt="Some tag"
          onClick={() => {
            setISActive(index);
            setMainPhone(false);
          }}
        />
        <p className={Styles["Influencer-Name"]}>
          <img src={influencer.socialMediaIcon} alt="insta"/>
          {influencer.name}
        </p>
        {influencer.infoImgs.map((infoImg, index) => (
          <img
            src={infoImg}
            className={Styles["Influencer-info-Img"]}
            alt="info"
            key={index}
          />
        ))}
      </div>
      <div>
        <Iphone
          isActive={isActive === index}
          video={influencer.video}
          className={`${Styles["Animated-Mobile-section"]} ${
            isActive === index
              ? Styles[`Animated-Mobile-section-${index}-active-${index}`]
              : Styles[`Animated-Mobile-section-${index}`]
          }`}
          className2={Styles["iphone-x-caseStudy"]}
        />
      </div>
    </>
  ));

  return (
    <article>
      <div
        className={Styles["Landing-Recent-Brands-contain"]}
        style={{ backgroundColor: "#F48164" }}
      >
        <Iphone
          className={`${
            mainPhone
              ? Styles["CaseStudies-Iphone-Secton"]
              : Styles["CaseStudies-Iphone-Secton-vanish"]
          }`}
        />
        <div
          className={`${Styles["Sticker-info"]} ${Styles["Sticker-info2"]}`}
          onClick={() => {
            setISActive(false);
            setMainPhone(true);
          }}
        >
          <img src={Hunnit} alt="Hunnit" />
          <p>
            Our team successfully helped Hunnit Apparel launch their premium
            activewear line with a Rest & Recover Campaign. Our strategy
            involved collaborating with a unique mix of macro and super
            influencers, creating high-quality content, and producing visually
            stunning materials. Through our efforts, we were able to position
            Hunnit's products as premium active apparel
          </p>
        </div>

        <div className={Styles["Landing-Brand-influcencer-info"]}>
          {renderedInfluencers}
        </div>
      </div>
    </article>
  );
};

export default CaseStudyContentComp2;
