import BS from "../Assets/Images/succesPng/Fire.png";
import WA from "../Assets/Images/succesPng/Woman Artist Medium Light Skin Tone.png";
import WT from "../Assets/Images/succesPng/Woman Technologist Light Skin Tone.png";
import M from "../Assets/Images/succesPng/Megaphone.png";

export const numberBoxArray = [
  {
    num: "100+",
    head: "Brand Successfull",
    img: BS,
  },
  {
    num: "80,000+",
    head: "Create Network",
    img: WA,
  },
  {
    num: "10+",
    head: "Regional Dialects",
    img: M,
  },
  {
    num: "250+",
    head: "Team Members",
    img: WT,
  },
];
