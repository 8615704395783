import React from "react";
import Styles from "../../Styles/Clients.module.css";
const ClientsComponent = ({ ClientsImgArray }) => {
  return (
    <div className={Styles["Clients-list-items"]}>
      {ClientsImgArray &&
        ClientsImgArray.map((item, index) => {
          return (
            <div key={index} className={Styles["Clients-list-item-box"]}>
              <img src={item.src} alt={item.alt} />
            </div>
          );
        })}
    </div>
  );
};

export default ClientsComponent;
