// influencer 1-------------------------------------------------------------------------------------
import Influencer1 from "../Assets/Images/CaseStudy/CaseStudyComp1/Ellipse 17.png";
import Influencer2 from "../Assets/Images/CaseStudy/CaseStudyComp1/Ellipse 18.png";
import Influencer3 from "../Assets/Images/CaseStudy/CaseStudyComp1/Ellipse 19.png";
import Instagram from "../Assets/Images/Social Icons/Instagram.png";
import like_1_1 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count.png";
import like_1_2 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-1.png";
import like_1_3 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-2.png";
import like_2_1 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-3.png";
import like_2_2 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-4.png";
import like_2_3 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-5.png";
import like_3_1 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-6.png";
import like_3_2 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-7.png";
import like_3_3 from "../Assets/Images/CaseStudy/CaseStudyComp1/view count-8.png";
import Video1 from "../Assets/video/CaseStudy1/ayushi verma.mp4";
import Video2 from "../Assets/video/CaseStudy1/liesha.mp4";
import Video3 from "../Assets/video/CaseStudy1/kat kriestien.mp4";

import urbanball from "../Assets/Images/CaseStudy/CaseStudyComp1/urban ball.png";

// Influence 2 assests------------------------------------------------------------------------------
import Influencer2_1 from "../Assets/Images/CaseStudy/CaseStudyComp2/Ellipse 17.png";
import Influencer2_2 from "../Assets/Images/CaseStudy/CaseStudyComp2/Ellipse 18.png";
import Influencer2_3 from "../Assets/Images/CaseStudy/CaseStudyComp2/Ellipse 19.png";
import Like_2_1_1 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count.png";
import Like_2_1_2 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-1.png";
import Like_2_1_3 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-2.png";
import Like_2_2_1 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-3.png";
import Like_2_2_2 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-4.png";
import Like_2_2_3 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-5.png";
import Like_2_3_1 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-6.png";
import Like_2_3_2 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-7.png";
import Like_2_3_3 from "../Assets/Images/CaseStudy/CaseStudyComp2/view count-8.png";
import Video_2_1 from "../Assets/video/CaseStudy2/RainaUttam.mp4";
import Video_2_2 from "../Assets/video/CaseStudy2/sainyatanija.mp4";
import Video_2_3 from "../Assets/video/CaseStudy2/Simranbedi.mp4";
import Hunnitball from "../Assets/Images/CaseStudy/CaseStudyComp2/logoball.png";

// Influencer 3 Assets---------------------------------------------------------------------------------
import Influencer3_1 from "../Assets/Images/CaseStudy/CaseStudyComp3/herby fashion img.png";
import Influencer3_2 from "../Assets/Images/CaseStudy/CaseStudyComp3/tiker soul.png";
import Influencer3_3 from "../Assets/Images/CaseStudy/CaseStudyComp3/meow meow.png";
import Likes3_1 from "../Assets/Images/CaseStudy/CaseStudyComp3/like1.png";
import Likes3_2 from "../Assets/Images/CaseStudy/CaseStudyComp3/like2.png";
import Likes3_3 from "../Assets/Images/CaseStudy/CaseStudyComp3/like3.png";
import Video_3_1 from "../Assets/video/CaseStudy3/herebyF.mp4";
import Video_3_2 from "../Assets/video/CaseStudy3/tinkerSoul.mp4";
import Video_3_3 from "../Assets/video/CaseStudy3/meow.mp4";
import Zball from "../Assets/Images/CaseStudy/CaseStudyComp3/logoball.png";
export const influencers1 = [
  {
    name: "leishapatidarrr",
    imgSrc: Influencer1,
    closebtn: urbanball,
    socialMediaIcon: Instagram,
    infoImgs: [like_1_1, like_1_2, like_1_3],
    video: Video2,
  },
  {
    name: "aayushiivermaa",
    imgSrc: Influencer2,
    closebtn: urbanball,
    socialMediaIcon: Instagram,
    infoImgs: [like_2_1, like_2_2, like_2_3],
    video: Video1,
  },
  {
    name: "kat.kristien",
    imgSrc: Influencer3,
    closebtn: urbanball,
    socialMediaIcon: Instagram,
    infoImgs: [like_3_1, like_3_2, like_3_3],
    video: Video3,
  },
];
export const influencers2 = [
  {
    name: "naina_uttam",
    imgSrc: Influencer2_1,
    closebtn: Hunnitball,
    socialMediaIcon: Instagram,
    infoImgs: [Like_2_1_1, Like_2_1_2, Like_2_1_3],
    video: Video_2_1,
  },
  {
    name: "sanya_tanejaa",
    imgSrc: Influencer2_2,
    closebtn: Hunnitball,
    socialMediaIcon: Instagram,
    infoImgs: [Like_2_2_1, Like_2_2_2, Like_2_2_3],
    video: Video_2_2,
  },
  {
    name: "simransethibedi",
    imgSrc: Influencer2_3,
    closebtn: Hunnitball,
    socialMediaIcon: Instagram,
    infoImgs: [Like_2_3_1, Like_2_3_2, Like_2_3_3],
    video: Video_2_3,
  },
];
export const influencers3 = [
  {
    name: "herebyfashion_",
    imgSrc: Influencer3_1,
    closebtn: Zball,
    socialMediaIcon: Instagram,
    infoImgs: [Likes3_3],
    video: Video_3_1,
  },
  {
    name: "thetinkersoul",
    imgSrc: Influencer3_2,
    closebtn: Zball,
    socialMediaIcon: Instagram,
    infoImgs: [Likes3_2],
    video: Video_3_2,
  },
  {
    name: "_.meow._meow_",
    imgSrc: Influencer3_3,
    socialMediaIcon: Instagram,
    closebtn: Zball,
    infoImgs: [Likes3_1],
    video: Video_3_3,
  },
];
