import React from "react";
import Styles from "../../../Styles/HeaderandInfo.module.css";

const HeaderandInfo = ({ children, title }) => {
  return (
    <header
      title={title || "Sub Heading"}
      className={Styles["Subheading-and-Info"]}
    >
      {children}
    </header>
  );
};

export default HeaderandInfo;
