import React from "react";
import Styles from "../../Styles/CaseStudyExtra.module.css";
import ResultImg from "../../Assets/Images/CaseStudy/vita-1.png";
import ConclusionImg from "../../Assets/Images/CaseStudy/vita-2.png";
import { Link } from "react-router-dom";
import video from "../../Assets/video/KRAZY FOX X VITA.mp4";
import VitaLogo from "../../Assets/Images/CaseStudy/vita/vitaLogo.png";
import playButton from "../../Assets/Images/CaseStudy/PlaybuttonImg.png";
import MainLogo from "../../Components/MainLogo/MainLogo";
import { useState } from "react";
import { useRef } from "react";

const Vita = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  function togglePlay() {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
    setIsPlaying(!isPlaying);
  }
  return (
    <section className={Styles["CaseStudies-extra-contain"]}>
      <div className={Styles["top-part"]}>
        <Link to="/CaseStudy">
          <span role="button" className={Styles["csbtn"]}>
            &#215;
          </span>
        </Link>
        <span className={Styles.MainHeading}>
          <img src={VitaLogo} alt="Vita" />X
          <MainLogo className={Styles.CaseStudyMainLogo} />
        </span>
        <p>Read about our amazing work with Moj</p>
      </div>
      <article className={Styles["Video-Section"]}>
        <div className={Styles["right-col"]}>
          <span onClick={togglePlay}>
            <img src={playButton} alt="Play/Pause" />
            {isPlaying ? (
              <span className={Styles["pause-icon"]}></span>
            ) : (
              <span className={Styles["play-icon"]}></span>
            )}
          </span>
          <video ref={videoRef} onClick={togglePlay} playsInline>
            <source src={video} type="video/mp4" />
            Video is not Supported
          </video>
        </div>
        <div className={Styles["left-col"]}>
          <h3>Milestones Achieved</h3>
          <div>
            <span>
              <h4>350+</h4>
              <p>Creator and influencer collaboration</p>
            </span>
            <span>
              <h4>12 Million</h4>
              <p>Reach on Instagram & youtube</p>
            </span>
          </div>
        </div>
      </article>
      <div className={Styles["MainContainer"]}>
        <h3>Client Background : </h3>
        <p>
          Vita is a free video editing app that wanted to scale up its business
          in India. Krazyfox was tasked with developing and executing a
          comprehensive marketing strategy to help Vita achieve their goals.
        </p>
        <h3> Challenge :</h3>
        <ul>
          <li>
            Vita faced significant challenges in reaching their target audience
            and generating awareness about their app. The intense competition in
            the video editing app market made it challenging to stand out and
            attract new users.
          </li>
        </ul>
        <div>
          <ul>
            <h3> Solution :</h3>
            <li>
              Krazyfox collaborated with Vita to develop a comprehensive
              marketing strategy that addressed their challenges. Over 12
              months, Krazyfox executed successful campaigns to help Vita reach
              a wide audience and achieve their goals.
            </li>
            <li>
              Krazyfox identified the right creators and influencers in the
              video editing space to collaborate with on Instagram and YouTube,
              ensuring that the campaigns resonated with the audience and
              generated the desired results.
            </li>
            <li>
              This strategy helped Vita reach over 5,00,00,000 people on
              Instagram and 7,00,00,000 people on YouTube.w
            </li>
            <li>
              Krazyfox's expertise in campaign execution, coupled with proven
              strategies and the ability to identify the right creators and
              influencers, played a critical role in the success of the
              collaboration..
            </li>
          </ul>
          <img src={ResultImg} alt="Result" />
        </div>

        <h3>Results :</h3>
        <ul>
          <li>
            Vita was able to scale up their business and achieve significant
            growth in India.
          </li>
          <li>
            The collaboration with Krazyfox resulted in widespread awareness and
            adoption of Vita's app, with a significant increase in user
            acquisition and retention.
          </li>
        </ul>
        <div>
          <img src={ConclusionImg} alt="Conclusion" />
          <ul>
            <h3>Conclusion :</h3>
            <li>
              Krazyfox's proven strategies, great campaign execution ability,
              and expertise in identifying the right creators and influencers
              made us the perfect service provider for Vita.
            </li>
            <li>
              If you're looking to scale up your business and achieve
              significant growth, Krazyfox can be your trusted partner. Connect
              with us today and let us help you achieve your go.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Vita;
