import React from "react";
import Styles from "../../Styles/StudioPag.module.css";
import SeamlessExp from "../../Assets/Images/studio section/mainpage-Img1.png";
import Iphone from "../../Components/UI/IphoneCaseStudy/Iphone";
import instagram from "../../Assets/Images/Social Icons/Instagram.png";
import Carousel from "./StudioWebCarousel";
import UrvashiVid from "../../Assets/video/Studio/content_mobile.mp4";
import Vid1 from "../../Assets/video/Studio/vid3.mp4";
import Vid2 from "../../Assets/video/Studio/vid1.mp4";
import Vid3 from "../../Assets/video/Studio/vid2.mp4";
import Vid4 from "../../Assets/video/Studio/vid4.mp4";
import Vid5 from "../../Assets/video/Studio/Vid5.mp4";
import mobileMainVid from "../../Assets/video/Studio/mobile-main-video.mp4";

import CarouselComp from "../../Components/UI/Carousel/Carousel";
import StudioBookNowBtn from "../../Components/UI/StudiobookNowBtn/StudioBookNowBtn";
import { Helmet } from "react-helmet";

const Studio = () => {
  return (
    <section>
      <Helmet>
        <title>
          KrazyFox Studio - Unleash Your Creativity with Our State-of-the-Art
          Content Creation Facilities
        </title>
        <meta
          name="description"
          content="KrazyFox Studio provides content creators with access to cutting-edge facilities for producing high-quality digital content. Elevate your creative process and bring your ideas to life with our professional equipment, studios, and expert support."
        />
        <meta
          name="keywords"
          content="KrazyFox Studio, content creation studio, digital content creation, content creator resources, professional equipment, creative studios"
        />
        <meta name="author" content="KrazyFox" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <article className={Styles.StudioBanner}>
        <div>
          <h2>Studio</h2>
        </div>
        <span className={`${Styles.circle1}`}>
          {Vid1 && (
            <video autoPlay muted loop playsInline>
              <source src={Vid1} type="video/mp4" />
            </video>
          )}
        </span>
        <span className={`${Styles.circle2}`}>
          {Vid1 && (
            <video autoPlay muted loop playsInline>
              <source src={Vid5} type="video/mp4" />
            </video>
          )}
        </span>
        <span className={`${Styles.circle3}`}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Findoorimg%2FStudio-Images4.jpg?alt=media&token=6d8b175d-5d38-4ebf-8f8e-856ddaccdeee"
            alt="circle 3"
          />
        </span>
        <span className={`${Styles.circle4}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Findoorimg%2FStudio-Images19.jpg?alt=media&token=13478055-ff11-4d4f-94f3-c84890f29665"
            alt="circle 4"
          />
        </span>
        <span className={`${Styles.circle5}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fup%20Equipments%2F2.png?alt=media&token=966b60c5-0859-4dd3-a746-e7c93d3c3b5e"
            alt="circle 5"
          />
        </span>
        <span className={`${Styles.circle6}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20outdoor%20img%2F4.png?alt=media&token=229c7ae4-b242-47d0-b5f0-af70d49d28f1"
            alt="circle 6"
          />
        </span>
        <span className={`${Styles.circle7}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20Indoor%20Img%2F3.png?alt=media&token=f75e7161-e4f9-41cb-8b8f-c0be05b348f2"
            alt="circle 7"
          />
        </span>
        <span className={`${Styles.circle8}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20outdoor%20img%2F2.png?alt=media&token=8f7d0cb7-5a5c-4923-adfd-e43a3389fa42"
            alt="circle 8"
          />
        </span>
        <span className={`${Styles.circle9}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20Indoor%20Img%2F1.png?alt=media&token=fc54c13f-99d5-48c4-8172-fc19bafaa632"
            alt="circle 9"
          />
        </span>
        <span className={`${Styles.circle10}`}>
          {Vid2 && (
            <video autoPlay muted loop playsInline>
              <source src={Vid2} type="video/mp4" />
            </video>
          )}
        </span>
        <span className={`${Styles.circle11}`}>
          {" "}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/krazyfox-fe84f.appspot.com/o/KrazyfoxNew4.0%2FImages%2FStudio%2Fupscaled%20Indoor%20Img%2F10.png?alt=media&token=ad752fc8-4c90-4b3e-8237-949c14e88075"
            alt="circle 11"
          />
        </span>
        <span className={`${Styles.circle12}`}>
          {Vid3 && (
            <video autoPlay muted loop playsInline>
              <source src={Vid3} type="video/mp4" />
            </video>
          )}
        </span>

        <Iphone className={Styles.StudioIphone} videoSrc={mobileMainVid} />
        <StudioBookNowBtn />
      </article>
      <section className={Styles.StudioSection}>
        <article className={Styles.StudioCarousel}>
          <Carousel />
        </article>

        <article className={Styles.StudioArticle2}>
          <h2>19 Different Themed Sets to Choose From</h2>
          <p>
            Creators get a versatile space to bring their ideas to life, without
            limits. With nineteen different sets, our studio provides the
            perfect environment for creating high-quality content that meets
            your unique needs.
          </p>
        </article>
        <article className={Styles.StudioArticles3}>
          <div className={Styles.StudioArticle3info}>
            <h2>We Provide you with a Seamless Experience</h2>
            <p>
              "Unlock the full potential of your production journey with our
              state-of-the-art in-house equipment, and enjoy seamless assistance
              every step of the way at our studio. Best of all, access to our
              equipment is completely free. Experience the ultimate in
              production excellence with us."
            </p>
          </div>
          <div className={Styles.StudioArticle3img}>
            <img src={SeamlessExp} alt="Seamless Experience" />
          </div>
        </article>
        <article>
          <CarouselComp seconds={10000} Showarrow={true}>
            <div className={Styles["StudioCarousel2"]}>
              <Iphone videoSrc={UrvashiVid} />
              <div className={Styles["cracked-div"]}>
                <h3>We Created Content with</h3>
                <p>
                  <img src={instagram} alt="instagram" />
                  urfi7i
                </p>
                <p>Uorfi Javed</p>
              </div>
            </div>
            <div className={Styles["StudioCarousel2"]}>
              <Iphone videoSrc={Vid1} />
              <div className={Styles["cracked-div"]}>
                <h3>We Created Content with</h3>
                <p>
                  <img src={instagram} alt="instagram" />
                  akasasing
                </p>
                <p>Akasa</p>
              </div>
            </div>
            <div className={Styles["StudioCarousel2"]}>
              <Iphone videoSrc={Vid4} />
              <div className={Styles["cracked-div"]}>
                <h3>We Created Content with</h3>
                <p>
                  <img src={instagram} alt="instagram" />
                  aasthagill
                </p>
                <p>Aastha Gill</p>
              </div>
            </div>
            <div className={Styles["StudioCarousel2"]}>
              <Iphone videoSrc={Vid5} />
              <div className={Styles["cracked-div"]}>
                <h3>We Created Content with</h3>
                <p>
                  <img src={instagram} alt="instagram" />
                  shehnaazgill
                </p>
                <p>Shehnaaz Gill</p>
              </div>
            </div>
          </CarouselComp>
          <h4 className={Styles.follow}>
            Follow us:
            <a href="https://www.instagram.com/krazyfox_/">
              <img src={instagram} alt="instagram" />
              krazyfox_studio
            </a>
          </h4>
        </article>
        <article>
          <h2>Visit Us Or Book us Today</h2>
          <p>
            6th Floor, KOTIA NIRMAN, New Link Rd, Industrial Area, Andheri West,
            Mumbai, Maharashtra 400102
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3828343141668!2d72.82986147533897!3d19.13471238208194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b76d92b02459%3A0x8feea7fce246e589!2sKrazyfox%20studio!5e0!3m2!1sen!2sin!4v1683000171897!5m2!1sen!2sin"
            width="600"
            height="450"
            loading="lazy"
            title="KrazyFox Mumbai location"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </article>
        <article>
          <div>
            <h2>Book Today</h2>
            <StudioBookNowBtn />
          </div>
          <div>
            <p>
              Are you eager to create compelling content, but struggling to find
              the perfect space to bring your ideas to life? Look no further!
              With just one click, we can provide you with the ideal environment
              to unleash your creativity and produce content that truly
              resonates with your audience.
            </p>
          </div>
        </article>
      </section>
    </section>
  );
};

export default Studio;
