import client1 from "../Assets/Images/clients/ClientsIMg/Zouk.png";
import client2 from "../Assets/Images/clients/ClientsIMg/airtel.png";
import client3 from "../Assets/Images/clients/ClientsIMg/amazon pay.png";
import client4 from "../Assets/Images/clients/ClientsIMg/b612.png";
import client5 from "../Assets/Images/clients/ClientsIMg/bumble.png";
import client6 from "../Assets/Images/clients/ClientsIMg/byjus.png";
import client7 from "../Assets/Images/clients/ClientsIMg/classmate.png";
import client8 from "../Assets/Images/clients/ClientsIMg/coin dcx.png";
import client9 from "../Assets/Images/clients/ClientsIMg/coinswitch.png";
import client10 from "../Assets/Images/clients/ClientsIMg/dream 11.png";
import client11 from "../Assets/Images/clients/ClientsIMg/workindia.png";
import client12 from "../Assets/Images/clients/ClientsIMg/flipkart.png";
import client13 from "../Assets/Images/clients/ClientsIMg/hipi.png";
import client14 from "../Assets/Images/clients/ClientsIMg/hunnit.png";
import client15 from "../Assets/Images/clients/ClientsIMg/kreditbee.png";
import client16 from "../Assets/Images/clients/ClientsIMg/kfc.png";
import client17 from "../Assets/Images/clients/ClientsIMg/lenskart.png";
import client18 from "../Assets/Images/clients/ClientsIMg/moj.png";
import client19 from "../Assets/Images/clients/ClientsIMg/mpl 11.png";
import client20 from "../Assets/Images/clients/ClientsIMg/narzoo.png";
import client21 from "../Assets/Images/clients/ClientsIMg/netflix.png";
import client22 from "../Assets/Images/clients/ClientsIMg/nyaaka.png";
import client23 from "../Assets/Images/clients/ClientsIMg/pepsi.png";
import client24 from "../Assets/Images/clients/ClientsIMg/philips.png";
import client25 from "../Assets/Images/clients/ClientsIMg/sharechat.png";
import client26 from "../Assets/Images/clients/ClientsIMg/smack video.png";
import client27 from "../Assets/Images/clients/ClientsIMg/spotify.png";
import client28 from "../Assets/Images/clients/ClientsIMg/sugar.png";
import client29 from "../Assets/Images/clients/ClientsIMg/tinder.png";
import client30 from "../Assets/Images/clients/ClientsIMg/urban company.png";
import client31 from "../Assets/Images/clients/ClientsIMg/vita.png";
import client32 from "../Assets/Images/clients/ClientsIMg/vivo.png";

export const ClientsImgArray = [
  { src: client1, alt: "Byjus" },
  { src: client2, alt: "Coinswitch" },
  { src: client3, alt: "Dream11" },
  { src: client4, alt: "Kfc_logo" },
  { src: client5, alt: "Logo_hipi" },
  { src: client6, alt: "Screenshot" },
  { src: client7, alt: "Spotify" },
  { src: client8, alt: "ZEE5_logo" },
  { src: client9, alt: "amazon" },
  { src: client10, alt: "bumbke" },
  { src: client11, alt: "class_mates" },
  { src: client12, alt: "image10" },
  { src: client13, alt: "image11" },
  { src: client14, alt: "image12" },
  { src: client15, alt: "image13" },
  { src: client16, alt: "image14" },
  { src: client17, alt: "image15" },
  { src: client18, alt: "image16" },
  { src: client19, alt: "image17" },
  { src: client20, alt: "image18" },
  { src: client21, alt: "image19" },
  { src: client22, alt: "image2" },
  { src: client23, alt: "image20" },
  { src: client24, alt: "image21" },
  { src: client25, alt: "image22" },
  { src: client26, alt: "image23" },
  { src: client27, alt: "image3" },
  { src: client28, alt: "image4" },
  { src: client29, alt: "image5" },
  { src: client30, alt: "image6" },
  { src: client31, alt: "image7" },
  { src: client32, alt: "logo-white" },
];
