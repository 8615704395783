import React, { useState, useEffect, useRef } from "react";
import Styles from "../../Styles/StudioPag.module.css";

import { items } from "../../Arrays/StudioCarouselArray";
const Carousel = () => {
  const [progress, setProgress] = useState(50);
  const [startX, setStartX] = useState(0);
  const [active, setActive] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const speedWheel = 0.02;
  const speedDrag = -0.1;
  const itemsRef = useRef([]);
  const cursorsRef = useRef([]);

  const getZindex = (array, index) =>
    array.map((_, i) =>
      index === i ? array.length : array.length - Math.abs(index - i)
    );

  const displayItems = (item, index, active) => {
    const zIndex = getZindex([...itemsRef.current], active)[index];
    item.style.setProperty("--zIndex", zIndex);
    item.style.setProperty(
      "--active",
      (index - active) / itemsRef.current.length
    );
  };

  const animate = () => {
    const newProgress = Math.max(0, Math.min(progress, 100));
    const newActive = Math.floor(
      (newProgress / 100) * (itemsRef.current.length - 1)
    );
    setActive(newActive);
    itemsRef.current.forEach((item, index) =>
      displayItems(item, index, newActive)
    );
  };

  useEffect(() => {
    animate();
  }, [progress]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setProgress((progress + 1) % 100);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [progress]);

  const handleWheel = (e) => {
    const wheelProgress = e.deltaY * speedWheel;
    setProgress((prevProgress) => prevProgress + wheelProgress);
    animate();
  };

  const handleMouseMove = (e) => {
    if (e.type === "mousemove") {
      cursorsRef.current.forEach(($cursor) => {
        $cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
      });
    }
    if (!isDown) return;
    const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
    const mouseProgress = (x - startX) * speedDrag;
    setProgress((prevProgress) => prevProgress + mouseProgress);
    setStartX(x);
    animate();
  };

  const handleMouseDown = (e) => {
    setIsDown(true);
    const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
    setStartX(x);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  return (
    <div
      className={Styles["StudioCarousel"]}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      onWheel={handleWheel}
    >
      {items &&
        items.map(({ title, num, imgSrc, info, colorText }, index) => (
          <div
            key={index}
            className={Styles["carousel-item"]}
            ref={(el) => (itemsRef.current[index] = el)}
          >
            <img src={imgSrc} alt={title} />
            {info && <p style={{ color: colorText }}>{info}</p>}
          </div>
        ))}
      <div
        className={Styles["cursor"]}
        ref={(el) => (cursorsRef.current[0] = el)}
      ></div>
      <div
        className={Styles["cursor2"]}
        ref={(el) => (itemsRef.current[-1] = el)}
      ></div>
    </div>
  );
};
export default Carousel;
