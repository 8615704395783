import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import MainBgImg from "./Assets/Images/BackgroundImages/baground_img.png";
import ClientsBgImg from "./Assets/Images/BackgroundImages/BackgroundClients.jpeg";
import StudioBgImg from "./Assets/Images/BackgroundImages/studioPage.png";
import contactBgImg from "./Assets/Images/BackgroundImages/contactus.jpg";
import clientMV from "./Assets/Images/BackgroundImages/clientMV.png";
import ParentComponent from "./ParentComponent";

function App() {
  const [bgImage, setBgImage] = useState(MainBgImg);
  const [bgColor, setBgColor] = useState("none");
  const location = useLocation();

  useEffect(() => {
    // Update the background image based on the current route
    const isMobile = window.innerWidth < 768; // Check for mobile devices
    switch (location.pathname) {
      case "/OurServices":
        setBgImage(isMobile ? MainBgImg : MainBgImg);
        break;
      case "/Studio":
        setBgImage(isMobile ? MainBgImg : StudioBgImg);
        break;
      case "/CaseStudy":
        setBgImage(isMobile ? MainBgImg : MainBgImg);
        break;
      case "/CaseStudy/Moj":
        setBgImage(isMobile ? "none" : "none");
        setBgColor(isMobile ? "#000D12" : "#000D12");
        break;
      case "/CaseStudies/Vita":
        setBgImage(isMobile ? "none" : "none");
        setBgColor(isMobile ? "#000D12" : "#000D12");
        break;
      case "/Clients":
        setBgImage(isMobile ? clientMV : ClientsBgImg);
        break;
      case "/ContactUs":
        setBgImage(isMobile ? contactBgImg : contactBgImg);
        break;
      case "/Studio/BookNow":
        setBgImage(isMobile ? contactBgImg : contactBgImg);
        break;
      default:
        setBgImage(isMobile ? MainBgImg : MainBgImg);
    }
  }, [location]);
  // cursor
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: bgColor || "none",
      }}
    >
      <ParentComponent />
      <div class="cursor"></div>
      <div class="cursor2"></div>
    </div>
  );
}

export default App;
