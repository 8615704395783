import React, { useEffect, useState } from "react";
import "./Iphone.css";
import Emoji1 from "../../../Assets/Images/Emoji 1.gif";
import Emoji2 from "../../../Assets/Images/emoji 2.gif";
import CarouselComp from "../Carousel/Carousel";
// import CarouselComp from '../../Assets/CaraouselSwiper/CarauselSlick'

const Iphone = ({ MainVideo, ImgArray, className, isActive, videoSrc }) => {
  return (
    <div className={`'mobile-section' ${className}`}>
      <div className="iphone-x">
        <img src={Emoji1} alt="emoji1" className="emoji1" />
        <div className="side">
          <div className="screen">
            {MainVideo && (
              <video autoPlay muted loop playsInline>
                <source src={MainVideo} type="video/mp4" />
                Video is not Supported
              </video>
            )}
            {isActive && (
              <video autoPlay muted loop playsInline>
                <source src={videoSrc} type="video/mp4" />
              </video>
            )}
            {ImgArray && (
              <CarouselComp>
                {ImgArray &&
                  ImgArray.map((item, index) => {
                    return (
                      <div key={index}>
                        <img src={item.src} alt={item.alt} />
                      </div>
                    );
                  })}
              </CarouselComp>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="header">
          <div className="sensor-1"></div>
          <div className="sensor-2"></div>
          <div className="sensor-3"></div>
        </div>
        <div className="volume-button"></div>
        <div className="power-button"></div>
        <img src={Emoji2} alt="emoji2" className="emoji2" />
      </div>
    </div>
  );
};

export default Iphone;
