import React from "react";
import Styles from "../../../Styles/Banner.module.css";

const Banner = ({ children, className }) => {
  return (
    <section className={`${Styles["UI-Banner"]} ${className}`}>
      {children}
    </section>
  );
};

export default Banner;
