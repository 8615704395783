import media1 from "../Assets/Images/MEDIAPRE/Frame 1108.png";
import media2 from "../Assets/Images/MEDIAPRE/Frame 1109.png";
import media3 from "../Assets/Images/MEDIAPRE/Frame 1110.png";
import media4 from "../Assets/Images/MEDIAPRE/Frame 1111.png";
import media5 from "../Assets/Images/MEDIAPRE/Frame 1118.png";

export const MediaPreArrays = [
  {
    src: media1,
    link: "https://theprint.in/ani-press-releases/krazyfox-launches-a-creative-studio-in-partnership-with-moj/1106556/",
  },
  {
    src: media2,
    link: "https://www.storyboard18.com/brand-makers/influencer-marketing-mavericks-story-behind-pandemic-born-krazyfoxs-quick-rise-2938.htm",
  },
  {
    src: media3,
    link: "https://www.outlookindia.com/website/story/business-news-influencer-marketing-agency-krazyfox-set-to-expand-globally/396825",
  },
  {
    src: media4,
    link: "https://www.aninews.in/news/business/business/indias-leading-influencer-marketing-agency-krazyfox-planning-to-expand-its-operations-globally20211005170835/",
  },
  {
    src: media5,
    link: "https://www.livemint.com/brand-stories/krazyfox-launches-a-creative-studio-in-partnership-with-moj-11661837610959.html",
  },
];
