import React from "react";
import Styles from "./Loader.module.css";
import MainLogo from "../Components/MainLogo/MainLogo";
const Loader = ({ isLoading }) => {
  return (
    <div className={Styles.LoaderContainer}>
      <MainLogo />
      <div className={Styles.loader}>
        <div className={Styles.container}>
          <div className={Styles.carousel}>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
            <div className={Styles.love}></div>
          </div>
        </div>
        <div className={Styles.container}>
          <div className={Styles.carousel}>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
            <div className={Styles.death}></div>
          </div>
        </div>
        <div className={Styles.container}>
          <div className={Styles.carousel}>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
            <div className={Styles.robots}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
