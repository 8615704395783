import Image1 from "../Assets/Images/SimpleApproach/image 24.png";
import Image2 from "../Assets/Images/SimpleApproach/image 25.png";
import Image3 from "../Assets/Images/SimpleApproach/image 26.png";

export const SimpleAproachArray = [
  {
    number: "#009DFF",
    heading2: "Building Campaign Strategy",
    content: [
      {
        heading3: "Brand Research & analysis",
        paragraph:
          "We analyze your brand and competition through market research to create a tailored influencer marketing strategy based on your unique needs and objectives.",
      },
      {
        heading3:
          "Personalized to meet your goals/Customize to meet your Objectives",
        paragraph:
          "We customize influencer marketing strategy to align with your goals, creating unique content ideas and selecting social media platforms to drive business growth.",
      },
    ],
    background: Image1,
  },
  {
    number: "#FF8300",
    heading2: "HANDPICKING THE BEST CREATORS",
    content: [
      {
        heading3: "Creator shortlisting ",
        paragraph:
          "Krazyfox carefully handpicks creators based on the quality of their content and audience reach for a seamless brand-creator match that delivers results.",
      },
      {
        heading3: "Available on Demand",
        paragraph:
          "Our services are available on demand, so you won't have to wait for days to onboard creators for your campaigns. We understand the urgency of your needs and strive to find the perfect creators waiting to collaborate with your brand, enabling you to start instantly.",
      },
    ],
    background: Image2,
  },
  {
    number: "#F21481",
    heading2: "ROI & ANALYTICS",
    content: [
      {
        heading3: "Real-time analysis",
        paragraph:
          "At Krazyfox, our interactive dashboard offers real-time campaign analytics and customizable performance metrics. Forecast campaign performance before going live and optimize it for success.",
      },
      {
        heading3: "Improve performance over time",
        paragraph:
          "With every new campaign you run with us, your performance gets better and better as we leverage historic campaign data, to optimize and achieve business objectives.",
      },
    ],
    background: Image3,
  },
];
