import React, { useState } from "react";
import HeaderComp from "./Components/MainHeaderComponent/HeaderComp";
import RouteComponent from "./Routers/RouteComponent";
import Footer from "./Components/MainFooterComponent/Footer";
import Loader from "./Loader/Loader";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
const ParentComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section style={{ overflow: "hidden", height: "auto" }}>
          <HeaderComp />
          <RouteComponent />
          <Outlet />
          <Footer />
        </section>
      )}
    </>
  );
};

export default ParentComponent;
