import React from "react";
import Styles from "../../../Styles/ContactUsButton.module.css";
import { Link } from "react-router-dom";

const ContactUsButton = ({ className }) => {
  return (
    <Link to="/ContactUs">
      <button className={`${Styles["button2"]} ${className}`}>
        Contact Us
      </button>
    </Link>
  );
};

export default ContactUsButton;
