import React from "react";
import Styles from "../../Styles/CaseStudyExtra.module.css";
import ResultImg from "../../Assets/Images/CaseStudy/moj-1-1.png";
import ConclusionImg from "../../Assets/Images/CaseStudy/moj-1-2.png";
import MojLogo from "../../Assets/Images/CaseStudy/Moj/MojLogo.png";
import { Link } from "react-router-dom";
import MainLogo from "../../Components/MainLogo/MainLogo";
import video from "../../Assets/video/KRAZYFOX X MOJ.mp4";
import playButton from "../../Assets/Images/CaseStudy/PlaybuttonImg.png";
import { useRef } from "react";
import { useState } from "react";
const Moj = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  function togglePlay() {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
    setIsPlaying(!isPlaying);
  }
  return (
    <section className={Styles["CaseStudies-extra-contain"]}>
      <div className={Styles["top-part"]}>
        <Link to="/CaseStudy">
          <span role="button" className={Styles["csbtn"]}>
            &#215;
          </span>
        </Link>
        <span className={Styles.MainHeading}>
          <img src={MojLogo} alt="MOJ" />X
          <MainLogo className={Styles.CaseStudyMainLogo} />
        </span>
        <p>Read about our amazing work with Moj</p>
      </div>
      <article className={Styles["Video-Section"]}>
        <div className={Styles["right-col"]}>
          <span onClick={togglePlay}>
            <img src={playButton} alt="Play/Pause" />
            {isPlaying ? (
              <span className={Styles["pause-icon"]}></span>
            ) : (
              <span className={Styles["play-icon"]}></span>
            )}
          </span>
          <video ref={videoRef} onClick={togglePlay} playsInline>
            <source src={video} type="video/mp4" />
            Video is not Supported
          </video>
        </div>
        <div className={Styles["left-col"]}>
          <h3>Milestones Achieved</h3>
          <div>
            <span>
              <h4>3500+</h4>
              <p>New Creators Joined Moj</p>
            </span>
            <span>
              <h4>7.6+Billion</h4>
              <p>#Krazyfox Hashtag Reach</p>
            </span>
          </div>
        </div>
      </article>
      <div className={Styles["MainContainer"]}>
        <h3>Challenge / Problem Statement : </h3>
        <p>
          Moj faced a significant challenge in supporting and nurturing the
          growth of a diverse community of content creators on their platform,
          spanning various languages and categories. The company had to ensure
          high engagement levels and retention rates to generate organic growth
          for both the creators and the platform.
        </p>
        <h3> Solution:</h3>
        <ul>
          <li>
            We tackled this challenge by implementing a multi-pronged approach.
            By creating a comprehensive onboarding process for the content
            creators, providing them with training and mentorship, and helping
            them to create quality content across various categories
          </li>
          <li>
            We leveraged our social media marketing expertise to promote the Moj
            platform's content, using relevant hashtags and collaborating with
            influencers to amplify reach.
          </li>
        </ul>
        <div>
          <ul>
            <h3> Result:</h3>
            <li>
              Over a span of 12 months, Krazyfox incubated 3500 + content
              creators on MOJ
            </li>
            <li>
              Generating over 2 million pieces of content across 11 different
              languages and categories like fashion, food, comedy, lifestyle,
              finance, and gaming.
            </li>
            <li>
              Hashtag #krazyfox reached the 7.9 billion mark and is growing day
              by day on the Moj app, indicating high levels of engagement and
              retention.
            </li>
            <li>
              The platform's retention rate increased to 74%, indicating that
              the content was resonating with the audience and driving organic
              growth.
            </li>
          </ul>
          <img src={ResultImg} alt="Result" />
        </div>
        <div>
          <img src={ConclusionImg} alt="Conclusion" />
          <ul>
            <h3>Conclusion :</h3>
            <li>
              The platform's retention rate increased to 74%, indicating that
              the content was resonating with the audience and driving organic
              growth.
            </li>
            <li>
              The results indicate that their multi-pronged approach has been
              effective in addressing the challenge and driving organic growth
              for both the creators and the platform. Krazyfox has demonstrated
              its expertise in digital marketing and social media, establishing
              itself as a leading player in the industry.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Moj;
