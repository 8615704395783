import React from "react";
import HubspotForm from "react-hubspot-form";
import img from "../../Assets/Images/contactus.png";

const ContactUs = () => {
  return (
    <>
      {" "}
      <div className="contact-us-div">
        <div>
          <img src={img} alt="cont Us img" />
        </div>
        <div className="contact-us-form-div">
          <HubspotForm
            portalId="24096273"
            formId="b17ad97d-3ad5-42aa-8188-7c70172f0553"
            onSubmit={() => console.log("Submit!")}
            onReady={(form) => console.log("Form ready!")}
            loading={<div>Loading...</div>}
            embedMode="inline"
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
