import React from "react";
import Styles from "../../../Styles/SeeMorebutton.module.css";

const SeeMorebutton = () => {
  return (
    <button className={Styles.SeeMorebutton}>
      <span class="text">Read More</span>
      <span>&#8594;</span>
    </button>
  );
};

export default SeeMorebutton;
