import React from "react";
import Styles from "../../Styles/Footer.module.css";
import SocialIconInsta from "../../Assets/Images/Social Icons/Instagram.png";
import SocialIconLink from "../../Assets/Images/Social Icons/Linkedin.png";
import ContactUsButton from "../UI/ContactUsButton/ContactUsButton";
import { Link } from "react-router-dom";
// import GetinTouchbtn from '../../UI/GetinTouchbtn/GetinTouchbtn'

const Footer = () => {
  return (
    <section className={Styles["Main-footer"]}>
      <div className={Styles["Main-footer-box"]}>
        <div className={Styles["Footer-Content-Container"]}>
          <div className={Styles["Social-Icons"]}>
            <h4>Follow Us</h4>
            <div>
              <a href="https://www.instagram.com/krazyfox_/">
                <img src={SocialIconInsta} alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com/company/krazyfox/">
                <img src={SocialIconLink} alt="Linkin" />
              </a>
            </div>
          </div>
          <div className={Styles["Site-map"]} title="Site Map">
            <h4>Site Map</h4>
            <nav>
              <ul>
                <Link to="/">
                  {" "}
                  <li>Home</li>
                </Link>
                <Link to="/Studio">
                  {" "}
                  <li>Studio</li>
                </Link>
                <Link to="/Services">
                  {" "}
                  <li>Services</li>
                </Link>
                <Link to="/Clients">
                  {" "}
                  <li>Clients</li>
                </Link>
                <Link to="/CaseStudy">
                  {" "}
                  <li>Case Studies</li>
                </Link>
              </ul>
            </nav>
          </div>
          <div className={Styles["Address-3"]}>
            <h4>KRAZYFOX SINGAPORE</h4>
            <p> 400 orchard Road, #11-08, Orchard Towers, Singapore 238875</p>
          </div>
          <div className={Styles["Address-1"]}>
            <h4>KRAZYFOX STUDIO MUMBAI</h4>
            <p>
              6th Floor, KOTIA NIRMAN, New Link Rd, Industrial Area, Andheri
              West, Mumbai, Maharashtra 400102
            </p>
          </div>
          <div className={Styles["Address-2"]}>
            <h4>KRAZYFOX PUNE</h4>
            <p>HQ8G+G82, Balewadi High St, Pune, Maharashtra 411045</p>
            <ContactUsButton />
          </div>
        </div>
        <p className={Styles["CopyRight"]}>
          © 2020 © Krazyfox Technology Pvt. Ltd. All Rights Reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
