import React from "react";
import CaseStudyContentComp from "../../Components/CaseStudyContentComp/CaseStudyContentComp";
import Styles from "../../Styles/CaseStudyBanner.module.css";
import SuccessComponent from "../../Components/SuccessComponent/SuccessComponent";
import CaseStudyContentComp2 from "../../Components/CaseStudyContentComp/CaseStudyContentComp2";
import CaseStudyContentComp3 from "../../Components/CaseStudyContentComp/CaseStudyContentComp3";
import HeaderandInfo from "../../Components/UI/Header&info/HeaderandInfo";
import MojImg from "../../Assets/Images/CaseStudy/case studies moj.png";
import VitaImg from "../../Assets/Images/CaseStudy/case study vita.png";
import SeeMorebutton from "../../Components/UI/SeeMoreButton/SeeMorebutton";
import ContactUsButton from "../../Components/UI/ContactUsButton/ContactUsButton";
import { Link } from "react-router-dom";
import BrandMobileVersion from "../../Components/RecentBrandMobileVersion/BrandMobileVersion";
import BrandMobileVersion2 from "../../Components/RecentBrandMobileVersion/Brand2";
import BrandMobileVersion3 from "../../Components/RecentBrandMobileVersion/brand3";
import CarouselComp from "../../Components/UI/Carousel/Carousel";

const CaseStudies = () => {
  return (
    <React.Fragment>
      <section className={Styles.CaseStudiesBanner}>
        <h2>To give you an idea, here's a bit about our work.</h2>
        <p>
          Krazyfox is an influencer marketing platform with over 80,000+ vetted
          content creators that helps businesses of all sizes succeed through
          customized, data-driven strategies. We focus on building authentic
          relationships between brands and their audiences to drive real
          results.
        </p>
        <ContactUsButton className={Styles.CaseStudiesBtn} />
        <SuccessComponent className={Styles.successbox} />
      </section>
      <CaseStudyContentComp />
      <CaseStudyContentComp2 />
      <CaseStudyContentComp3 />
      <CarouselComp seconds={10000} Showarrow={true}>
        <BrandMobileVersion />
        <BrandMobileVersion2 />
        <BrandMobileVersion3 />
      </CarouselComp>
      <article className={Styles["CaseStudies-partner"]}>
        <HeaderandInfo>
          <h2>Case Studies</h2>
          <p>
            Our ideas and strategies are fueled by enthusiasm, ingenuity, and
            imagination. By blending insights with various media, we develop
            campaigns that ignite passion and inspire action.
          </p>
        </HeaderandInfo>
        <div className={Styles["Case-Studies"]}>
          <div className={Styles["Case-Studies-box"]}>
            <div className={Styles["Case-Studies-info"]}>
              <h3>Moj X KRAZYFOX</h3>
              <p>
                Our partnership with Moj has been fantastic. Together, we
                developed innovative solutions that supported and nurtured their
                diverse community of content creators. Our expertise helped Moj
                identify trends and topics that resonated with users in various
                languages and categories. We created optimized content and
                improved engagement, resulting in higher retention rates for
                creators and the platform. We also collaborated on building a
                robust moderation system that ensured a safe environment for
                their community.
              </p>
              <Link className="header-link" to="/CaseStudy/Moj">
                <SeeMorebutton />
              </Link>
            </div>
            <div className={Styles["Case-Studies-img"]}>
              <img src={MojImg} alt="MojCase Studies" />
            </div>
          </div>
          <div className={Styles["Case-Studies-box"]}>
            <div className={Styles["Case-Studies-info"]}>
              <h3>Vita X KrazyFox</h3>
              <p>
                Our partnership with Vita was amazing, but they faced challenges
                in reaching their target audience and standing out in the
                competitive video editing app market. We helped them
                differentiate themselves with targeted advertising and
                personalized features that improved user retention. Through our
                collaboration, we built a strong foundation for their continued
                success.
              </p>
              <Link className="header-link" to="/CaseStudy/Vita">
                <SeeMorebutton />
              </Link>
            </div>
            <div className={Styles["Case- Studies-img"]}>
              <img src={VitaImg} alt="VitaCase Studies" />
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default CaseStudies;
