import ServicesImg1 from "../Assets/Images/Services/ServicesCardPng/digital content creation.png";
import ServicesImg2 from "../Assets/Images/Services/ServicesCardPng/digital strategy.png";
import ServicesImg3 from "../Assets/Images/Services/ServicesCardPng/influencer out reach management.png";
import ServicesImg4 from "../Assets/Images/Services/ServicesCardPng/meme marketing.png";
import ServicesImg5 from "../Assets/Images/Services/ServicesCardPng/social media marketing.png";
import ServicesImg6 from "../Assets/Images/Services/ServicesCardPng/talent Management.png";

export const ImgArray = [
  { src: ServicesImg1, alt: "Digital Content Creation" },
  { src: ServicesImg2, alt: "something" },
  { src: ServicesImg3, alt: "Digital Strategy" },
  { src: ServicesImg4, alt: "Influencer Out Reach Management" },
  { src: ServicesImg5, alt: "Meme Markting" },
  { src: ServicesImg6, alt: "Social media Marketing" },
];
