import React from "react";
import Styles from "../../../Styles/StudioBooknowBtn.module.css";
import { Link } from "react-router-dom";

const StudioBookNowBtn = () => {
  return (
    <Link to="/Studio/BookNow">
      <button class={Styles["Btn"]}>Book Now</button>
    </Link>
  );
};

export default StudioBookNowBtn;
