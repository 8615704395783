import React from "react";
import Banner from "../../Components/UI/Banner/Banner";
import Iphone from "../../Components/UI/Iphone/Iphone";
import HeaderandInfo from "../../Components/UI/Header&info/HeaderandInfo";
import { ServicesContentArray } from "../../Arrays/ServicesCardArray";
import Styles from "../../Styles/Services.module.css";
import ContactUsButton from "../../Components/UI/ContactUsButton/ContactUsButton";
import { ImgArray } from "../../Arrays/ImgArray";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Our Digital Services-Krazyfox</title>
        <meta
          name="description"
          content="KrazyFox offers top-notch services in influencer marketing, digital content creation, social media management, meme campaigns, and talent management. Reach your target audience effectively with our comprehensive digital marketing solutions."
        />
        <meta
          name="keywords"
          content="influencer marketing, digital content creation, social media management, meme campaigns, talent management, digital marketing services, KrazyFox"
        />
        <meta name="author" content="KrazyFox" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Banner className={Styles.ServicesBanner}>
        <div>
          <h2>Our Services</h2>
          <p>
            Is this a must have for your brand?
            <br />
            We're ready to make it a reality!
          </p>
          <ContactUsButton />
        </div>
        <Iphone ImgArray={ImgArray}></Iphone>
      </Banner>
      <article>
        <HeaderandInfo>
          <h2>We Design, We Influence and Awe.</h2>
          <p>
            We're a digital agency that creates campaigns designed to move and
            inspire audiences through a range of channels. Want to know more
            about our approach? Here's a brief synopsis of how we function.
          </p>
        </HeaderandInfo>
        <div className={Styles["Servies-Page-Card-Container"]}>
          {ServicesContentArray &&
            ServicesContentArray.map((item, index) => {
              return (
                <div
                  className={Styles["Services-Page-card-box"]}
                  key={index}
                  style={{ background: `${item.color}` }}
                >
                  <h3>{item.heading}</h3>
                  <div className={Styles["Services-Page-card-box-info"]}>
                    <img src={item.ContentImg} alt={item.heading} />
                    <p>{item.info}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </article>
    </React.Fragment>
  );
};

export default Services;
