import React from "react";
import Styles from "../../Styles/MainLogo.module.css";
import logo from "../../Assets/Images/MainLogo/KrazyfoxLogo.png";
import { Link } from "react-router-dom";
const MainLogo = ({ className }) => {
  return (
    <Link to="/">
      <div className={`${Styles["Main-Logo-css"]} ${className}`}>
        <img src={logo} alt="Main Logo" />

        <h1>
          <strong>Krazy</strong>
          <strong>Fox</strong>
        </h1>
      </div>
    </Link>
  );
};

export default MainLogo;
